@import '../../sass/MyExperience.module.scss';

.header {
    background-color: $fk-grey;
    height: 65px;
    position: relative;

    .logo {
        width: 150px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        padding-left: 15px;
    }
}
