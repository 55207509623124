@import '~office-ui-fabric-react/dist/sass/References.scss';

//color variables
$fk-blue: #0063be;
$fk-grey: #efefef;
$fk-dark-grey: #636363;
$fk-white: #fff;
$fk-black: black;

:export {
    fkBlue: $fk-blue;
    fkGrey: $fk-grey;
    fkDarkGrey: $fk-dark-grey;
    fkWhite: $fk-white;
}

// Font Family
$default-font-family: Verdana;

// Fonts
$font-size-minimum: 8px;
$font-size-smaller: 10px;
$font-size-small: 14px;
$font-size-medium: 18px;
$font-size-large: 25px;
$font-size-xlarge: 33px;
