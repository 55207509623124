@import '../../sass/MyExperience.module.scss';

.paragraph {
    color: $fk-dark-grey;
    font-family: $default-font-family;
    font-size: $font-size-small;
    font-style: italic;
    padding-top: 50px;
    text-align: center;
}
