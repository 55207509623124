@import '../../sass/MyExperience.module.scss';

.container {
    background-color: $fk-grey;
    font-family: $default-font-family;
    margin-top: 15px;

    .box {
        padding: 50px 50px;

        @media (max-width: 640px) {
            padding: 30px 10px;
        }
    }
}
